export default class {
  execute(properties, component) {
    const t = TrelloPowerUp.iframe()

    t.authorize(properties.oauthUrl, null).then((token) => {
      if (token) {
        // Create and login user in the current frame.
        GLib.action.execute({
          "action": "http/post",
          "url": $tpu.urls.authorizations(),
          "formData": {
            "token": token
          }
        }, component)
      }
    })
  }
}
